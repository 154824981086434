.disease-areas-button-group {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px 40px 0px;
}

.disease-areas-button-group-right {
  margin-left: 20px;
}

.patient-count-info-div {
  font-size: 14px;
  font-style: italic;
  padding: 7px 0px 7px 0px;
}

.grid-loading-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.disease-area-count-grid {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.disease-area-cell-button {
  color: #4001ff;
  background-color: transparent;
  border: none;
  text-decoration: none;
}

.disease-area-cell-button:focus {
  background-color: transparent;
  border: none;
  text-decoration: none;
}

.count-type-tabs {
  margin: 0px 0px 30px 0px;
}

.count-grid-cell-button {
  color: #4001ff;
  background-color: transparent;
  text-decoration: none;
  border: none;
}

.count-grid-cell-button:disabled {
  color: #4001ff;
  background-color: transparent;
  text-decoration: none;
  border: none;
}

.patient-record-utilities {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 30px 0px;
}

.disease-area-patient-record-grid {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiTablePagination-selectLabel {
  margin-top: 1rem;
}

.MuiTablePagination-displayedRows {
  margin-top: 1rem;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
