.historic-counts {
  display: flex;
  align-items: top;
}

.historic-counts-loading {
  padding-left: 50px;
  font-size: 24px;
  color: #4001ff;
}

.historic-count-stats {
  font-size: 22px;
  width: 475px;
}

.historic-counts-filters {
  width: 270px;
}

.historic-counts-filters-group {
  border-bottom: 1px solid rgb(187, 183, 183);
  margin: 0px 0px 30px 0px;
}

.historic-counts-filters-group-header {
  font-size: 24px;
  font-style: bold;
  margin: 0px 0px 20px 0px;
}

.historic-counts-filters-subgroup {
  border-top: 1px solid rgb(187, 183, 183);
  align-content: center;
}

.historic-counts-filters-subgroup-header {
  padding: 0px 0px 2px 0px;
}

.historic-counts-filters-radio {
  padding: 10px 0px 10px 7px;
}

.historic-counts-filters-radio-buttons {
  font-size: 15px;
  padding: 2px 0px 0px 0px;
}

.historic-counts-filters-date {
  justify-content: space-between;
  padding: 10px 0px 20px 7px;
}

.historic-counts-filters-accordian-item {
  width: 260px;
  border: none;
  padding: 10px 0px 10px 7px;
  background: white;
  text-align: justify;
  display: flex;
  justify-content: space-between;
}
.historic-counts-filters-accordian-item:focus {
  background: white;
  color: black;
}

.historic-counts-filters-accordian-item-selection {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 10px 0px;
  width: 265px;
}

.historic-counts-filters-accordian-item-test {
  display: flex;
  align-items: baseline;
  padding: 0px 0px 2px 0px;
}

.historic-counts-filters-accordian-item-checkbox {
  width: 10px;
  height: 10px;
  margin: 0px 10px 0px 25px;
}

.historic-counts-filters-accordian-item-checkboxlbl {
  color: rgb(74, 73, 73);
}

.historic-counts-filters-select-all-checkbox {
  width: 10px;
  height: 10px;
  margin: 5px 10px 20px 10px;
}

.historic-counts-filters-select-all-checkboxlbl {
  color: rgb(74, 73, 73);
}
